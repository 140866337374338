import React, { useCallback, useMemo } from 'react'
import { Icon } from 'scala'
import classNames from 'classnames'
import styles from './styles.module.scss'

type CounterProps = {
  label?: string
  value: number
  min?: number
  max?: number
  onChange: (value: number) => void
}

export const Counter: React.FC<CounterProps> = ({
  label,
  value = 0,
  min,
  max,
  onChange
}) => {
  const onIncrement = useCallback(() => {
    if (max !== undefined && value >= max) return
    onChange(value + 1)
  }, [max, onChange, value])

  const onDecrement = useCallback(() => {
    if (min !== undefined && value <= min) return
    onChange(value - 1)
  }, [min, onChange, value])

  const disableIncrement = useMemo(
    () => max !== undefined && value >= max,
    [max, value]
  )
  const disableDecrement = useMemo(
    () => min !== undefined && value <= min,
    [min, value]
  )

  return (
    <div
      id="number-input"
      aria-label="number-input"
      className={styles.inputContainer}
    >
      {label && <span className={styles.label}>{label}</span>}
      <div className={styles.inputGroup}>
        <button
          type="button"
          aria-label="decrement"
          onClick={onDecrement}
          className={classNames(styles.button, {
            [styles.disabledButton]: disableDecrement
          })}
          disabled={disableDecrement}
        >
          <Icon name="minus" width={16} height={16} />
        </button>
        <div className={styles.value}>{value}</div>
        <button
          type="button"
          aria-label="increment"
          onClick={onIncrement}
          className={classNames(styles.button, {
            [styles.disabledButton]: disableIncrement
          })}
          disabled={disableIncrement}
        >
          <Icon name="plus" width={16} height={16} />
        </button>
      </div>
    </div>
  )
}
