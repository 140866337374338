import { iPlanData } from '@moises-ai/pricing/dist/types'
import { UserContext } from 'scala/src/types'
import { config } from '../../../../config'
import { Plan } from './use-checkout-common'

export const getLinkPayment = (
  cycle: any,
  env: string,
  provider: string,
  user: UserContext
): string => {
  const hasTrial = !user?.subscription?.hasUsedStripeTrial

  const payment = cycle?.providers?.[env]?.[provider]?.paymentLink?.find(
    (p: any) => p?.hasTrial === hasTrial
  )

  if (!payment?.link) {
    return ''
  }

  let link = payment?.link

  if (user) {
    link += `?client_reference_id=${user?.id}&prefilled_email=${user?.email}`
  }

  if (payment?.promoCode) {
    link += `&prefilled_promo_code=${payment?.promoCode}`
  }

  return link
}

export const getPlanId = (
  cycle: any,
  env: string,
  provider: string
): string => {
  return cycle?.providers?.[env]?.[provider]?.planIds?.[0] || ''
}

export const getPricePlan = (
  plan: iPlanData,
  includePaymentLink?: boolean,
  user?: UserContext
): Plan['price'] => {
  const { env } = config

  const monthly = plan?.cycles?.find((c) => c?.name === 'monthly')
  const yearly = plan?.cycles?.find((c) => c?.name === 'yearly')

  return {
    monthly: {
      regularPrice: monthly?.regularPrice.toFixed(2) || '0.00',
      monthlyRegularPrice: parseFloat(
        (monthly?.friendlyFormat || '0').replace(',', '.')
      ).toFixed(2),
      planId: getPlanId(monthly, env, 'stripe'),
      paymentLink:
        includePaymentLink && user
          ? getLinkPayment(monthly, env, 'stripe', user)
          : undefined
    },
    yearly: {
      regularPrice: yearly?.regularPrice.toFixed(2) || '0.00',
      monthlyRegularPrice: parseFloat(
        (yearly?.friendlyFormat || '0').replace(',', '.')
      ).toFixed(2),
      planId: getPlanId(yearly, env, 'stripe'),
      paymentLink:
        includePaymentLink && user
          ? getLinkPayment(yearly, env, 'stripe', user)
          : undefined
    }
  }
}
