/* eslint no-console: 0 */
// import { captureEvent } from '@sentry/nextjs'
import { useCallback, useContext } from 'react'
import { useRouter } from 'next/router'
import { useEffectOnce } from 'react-use'
import { Context as ContextAppFrame } from 'scala'
import { useGetUtmStorage } from '..'
import ReactGA from '../../../lib/react-ga'
import { isDesktopApp } from '../../authentication/use-authentication/providers/helpers'

interface EventParams {
  name: string
  userId?: string
  category: string
  label?: string
  fbEventId?: string
  customAttributes?: {
    [key: string]: string | string[] | number | boolean | undefined
  }
}

interface UseEvent {
  sendEvent(event: EventParams): any
}

const SESSION_STORAGE_KEY = 'moises:log_events'

export const useEvent = (): UseEvent => {
  const { query } = useRouter()
  const { getUTM } = useGetUtmStorage()
  const { user } = useContext(ContextAppFrame)
  const { id = null, shouldIdentifyOnCIO = false } = user || {}

  useEffectOnce(() => {
    if (query?.log_events) {
      sessionStorage.setItem(SESSION_STORAGE_KEY, 'true')
    }
  })

  const sendEvent = useCallback(
    ({
      name,
      userId = id,
      category,
      label,
      customAttributes,
      fbEventId
    }: EventParams) => {
      if (!userId?.length) {
        return
      }

      const extendedCustomAttributes = {
        ...customAttributes,
        user_id: userId,
        locale: navigator.language,
        // @ts-expect-error
        device_os: window?.navigator?.userAgentData?.platform || 'not set',
        client: isDesktopApp ? 'studio-desktop-app' : 'studio',
        ...getUTM()
      }

      window?.rudderanalytics?.track(name, {
        category,
        label,
        ...{
          ...extendedCustomAttributes,
          shouldIdentifyOnCIO
        }
      })

      let extraFbParams
      if (fbEventId) extraFbParams = { eventID: fbEventId }

      window?.fbq(
        'track',
        name,
        {
          category,
          label: label || category,
          ...extendedCustomAttributes
        },
        extraFbParams
      )

      ReactGA.event({
        category,
        action: name,
        label: label || category,
        ...extendedCustomAttributes
      })

      if (sessionStorage.getItem(SESSION_STORAGE_KEY)) {
        console.group('event:', name)
        console.table({
          category,
          label,
          ...extendedCustomAttributes
        })
        console.groupEnd()
      }
    },
    [getUTM, shouldIdentifyOnCIO, id]
  )

  return {
    sendEvent
  }
}
