import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useRouter } from 'next/router'
import React, { useCallback, useContext, useMemo } from 'react'
import { Button, Context } from 'scala'
import { Group, GroupOwner, GroupMember } from 'scala/src/types/group.types'
import styles from '../../../modules/group/manage/manage.module.scss'
import { useEvent } from '../../analytics'

type UseDataManageGroup = {
  owner: GroupOwner
  members: GroupMember[] | undefined
  titleGroup: string
  subtitleGroup: string
  disclaimerGroup: string | null
  group: Group
  groupIsFull: boolean
  modalTexts: {
    title: string
    info: string
    confirmLabel: string
    cancelLabel: string
    onConfirm: () => void
  }
  ActionManage: React.JSX.Element
  isOwner: boolean
  isMember: boolean
  isPremium: boolean
  isPro: boolean
  isEnabledGroupPlan: boolean
  isEnabledManagement: boolean
  seats: number | undefined
  handleRedirectToHome: () => void
  plan: 'premium' | 'pro'
  cycle: 'monthly' | 'yearly'
  nextCycleSeats: number | undefined
}

export const useDataManageGroup = (): UseDataManageGroup => {
  const { i18n } = useLingui()
  const { user } = useContext(Context)
  const { push } = useRouter()
  const { sendEvent } = useEvent()

  const group: Group = useMemo(() => user?.groups?.[0], [user?.groups])

  const isOwner = useMemo(() => group && !!group?.isOwner, [group])

  const isMember = useMemo(() => group && !group?.isOwner, [group])

  const owner = useMemo(() => {
    if (group?.owner) {
      return group?.owner
    }
    return {
      name: user?.name,
      email: user?.email,
      avatar: user?.profilePictureUrl
    }
  }, [group, user])

  const seats = useMemo(() => group?.currentSeats, [group])
  const nextCycleSeats = useMemo(() => group?.nextCycleSeats, [group])
  const members = useMemo(() => group?.members, [group])

  const isPremium = useMemo(() => user?.subscription?.isPremium, [user])
  const isPro = useMemo(() => user?.subscription?.isPro, [user])
  const isFree = useMemo(() => user?.subscription?.plan === 'free', [user])

  const plan = useMemo(() => user?.subscription?.plan, [user])
  const cycle = useMemo(() => user?.subscription?.details?.planCycle, [user])

  const titleGroup = useMemo(() => {
    if (isPro || isPremium) {
      return i18n
        ._(t`title_moises_premium_group`)
        .replace('**Premium**', `${isPro ? 'Pro' : 'Premium'}`)
    }

    return t`my_group_screen_title`
  }, [i18n, isPro, isPremium])

  const subtitleGroup = useMemo(() => {
    if (group?.isOwner) {
      return `${group?.occupiedSeats}/${
        group?.currentSeats
      } ${t`licenses_line`}`
    }

    return `0/0 ${t`license_singular_line`}`
  }, [group])

  const disclaimerGroup = useMemo(() => {
    if (!isPro && !isPremium) {
      return i18n._(t`pricing_save_with_group`).replace('**40%**', '40%')
    }
    return null
  }, [i18n, isPremium, isPro])

  const handleManageLicense = useCallback(
    (event: string) => {
      if (isOwner) {
        return push({
          pathname: '/group/licenses/',
          query: {
            plan,
            cycle,
            planType: 'group',
            seats: nextCycleSeats || seats,
            source: event
          }
        })
      }
      sendEvent({
        name: 'clicked_unlock_paywall',
        category: 'growth',
        customAttributes: {
          source: event
        }
      })

      return push({
        pathname: '/pricing',
        query: { planType: 'group', source: event }
      })
    },
    [push, isOwner, cycle, plan, seats, nextCycleSeats, sendEvent]
  )

  const handleRedirectToHome = useCallback(() => {
    push('/library')
  }, [push])

  const ActionManage = useMemo(() => {
    const title =
      user?.subscription?.subscriptionType === 'group-owner'
        ? t`manage_license_button`
        : t`start_group_button`

    return (
      <Button
        quiet
        small
        radius={1}
        title={title}
        className={styles.buttonManage}
        onClick={() => handleManageLicense('start-group-plan-CTA')}
      />
    )
  }, [user, handleManageLicense])

  const groupIsFull = useMemo(() => user?.groups?.[0]?.isFull, [user])

  const modalTexts = useMemo(() => {
    if (groupIsFull) {
      return {
        title: t`grow_group_banner_title`,
        info: t`grow_group_banner_description`,
        confirmLabel: t`grow_group_button`,
        cancelLabel: t`cancel`,
        onConfirm: () => handleManageLicense('paywall-no-group-licenses')
      }
    }

    return {
      title: t`paywall_group_plan_title`,
      info: t`paywall_group_plan_description`,
      confirmLabel: t`start_group_plan_button`,
      cancelLabel: t`cancel`,
      onConfirm: () => handleManageLicense('paywall-group-plan-invite')
    }
  }, [groupIsFull, handleManageLicense])

  const isEnabledGroupPlan = useMemo(
    () => user?.featureFlags?.webGroupPlan,
    [user]
  )

  const isIndividualSubscription = useMemo(
    () => user?.subscription?.subscriptionType === 'individual' && isPremium,
    [user, isPremium]
  )

  const isSubscriptionTrial = useMemo(() => {
    return user?.subscription?.details?.providerName === 'trial' && isPremium
  }, [user, isPremium])

  const isEnabledManagement = useMemo(
    () =>
      isEnabledGroupPlan &&
      !isSubscriptionTrial &&
      (isOwner || isFree || isIndividualSubscription),
    [
      isEnabledGroupPlan,
      isIndividualSubscription,
      isOwner,
      isFree,
      isSubscriptionTrial
    ]
  )

  return {
    titleGroup,
    subtitleGroup,
    disclaimerGroup,
    ActionManage,
    owner,
    members,
    groupIsFull,
    modalTexts,
    group,
    isOwner,
    isMember,
    isEnabledManagement,
    isEnabledGroupPlan,
    isPro,
    isPremium,
    seats,
    handleRedirectToHome,
    plan,
    cycle,
    nextCycleSeats
  }
}
