import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import React from 'react'
import { Tooltip } from 'scala'
import styles from './styles.module.scss'
import { Counter } from '../../form/counter'
import { UseControlLicenses } from '../../../hooks/checkout-new/group/use-control-licenses'

export const SeatsCounter: React.FC<UseControlLicenses> = ({
  min,
  max,
  price,
  currencyCode,
  counterValue,
  onChange
}) => {
  const { i18n } = useLingui()

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <p>{i18n._(t`checkout_number_licenses`)}</p>

        <div className={styles.counter}>
          {counterValue === min && (
            <Tooltip
              className={styles.tooltip}
              title={i18n._(t`checkout_number_licenses`).replace('3', `${min}`)}
              top
            />
          )}
          <Counter
            min={min}
            max={max}
            value={counterValue}
            onChange={onChange}
          />
        </div>
      </div>

      <div className={styles.content}>
        <p className={styles.license}>{i18n._(t`checkout_cost_license`)}</p>
        <p className={styles.license}>
          {currencyCode} {price}
        </p>
      </div>
    </div>
  )
}
