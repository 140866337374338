import { Context as ContextAppFrame } from 'scala'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { usePricing } from '../../../pricing/use-pricing'
import { Context } from '../../../../context'
import { getPricePlan } from './utils'

export interface PlanPrice {
  price?: string
  regularPrice: string
  monthlyPrice?: string
  monthlyRegularPrice: string
  paymentLink?: string
  planId?: string
}

interface PlanItemPrice {
  [key: string]: PlanPrice
  monthly: PlanPrice
  yearly: PlanPrice
}

export interface Plan {
  currencyCode: string
  currencySymbol: string
  price: PlanItemPrice
  priceGroup?: PlanItemPrice
}

export interface Plans {
  global: Plan
  pro: Plan
}

export interface UseCheckoutCommon {
  cycle: 'monthly' | 'yearly'
  plan: 'premium' | 'pro'
  planType: 'individual' | 'group'
  plans: Plans
  onChangePlan(e: React.ChangeEvent<HTMLSelectElement>): void
  onPaymentFail(reason?: string | undefined): void
}

export const useCheckoutCommon = (): UseCheckoutCommon => {
  const router = useRouter()
  const { user } = useContext(ContextAppFrame)

  const [cycle, setCycle] = useState<UseCheckoutCommon['cycle']>(
    router.query?.cycle === 'monthly' ? 'monthly' : 'yearly'
  )

  const [plan, setPlan] = useState<UseCheckoutCommon['plan']>(
    router.query?.plan === 'pro' ? 'pro' : 'premium'
  )

  const [planType] = useState<UseCheckoutCommon['planType']>(
    router.query?.planType === 'group' ? 'group' : 'individual'
  )

  const {
    toast: { add: addToast }
  } = useContext(Context)

  const {
    premium: planPremium,
    pro: planPro,
    group: { premium: planGroupPremium, pro: planGroupPro }
  } = usePricing()

  const global: Plan = useMemo(() => {
    return {
      currencyCode: planPremium.currencyCode,
      currencySymbol: planPremium.currencySymbol,
      price: getPricePlan(planPremium),
      priceGroup: getPricePlan(planGroupPremium)
    }
  }, [planPremium, planGroupPremium])

  const pro: Plan = useMemo(() => {
    return {
      currencyCode: planPro.currencyCode,
      currencySymbol: planPro.currencySymbol,
      price: getPricePlan(planPro, true, user),
      priceGroup: getPricePlan(planGroupPro, true, user)
    }
  }, [planPro, planGroupPro, user])

  const onChangePlan = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const [cycleValue, nameValue] = e.target.value.split('-')
      setCycle(cycleValue as 'monthly' | 'yearly')
      setPlan(nameValue as 'premium' | 'pro')
    },
    []
  )

  const onPaymentFail = useCallback(
    (reason: string | undefined) => {
      addToast({
        icon: null,
        type: 'error',
        closable: true,
        description: reason || 'Payment failed'
      })
    },
    [addToast]
  )

  return {
    plan,
    cycle,
    planType,
    plans: {
      global, // premium
      pro
    },
    onChangePlan,
    onPaymentFail
  }
}
