import { plans } from '@moises-ai/pricing/dist/plans'
import { euroZoneCountryCodes } from '@moises-ai/pricing/dist/euro-zone'
import { highZoneCountryCodes } from '@moises-ai/pricing/dist/high-zone'
import { type iPlanData } from '@moises-ai/pricing/dist/types'
import { Context } from 'scala'
import { useContext } from 'react'
import useCountry from '../misc/use-country/use-country'

interface UsePricing {
  pro: iPlanData
  premium: iPlanData
  countryCode: string
  group: {
    pro: iPlanData
    premium: iPlanData
  }
}

const australia = {
  countryCode: 'AU',
  pro: plans.pro.AU,
  premium: plans.premium.AU,
  group: {
    pro: plans.pro.AU_GROUP,
    premium: plans.premium.AU_GROUP
  }
}

const europe = {
  countryCode: 'ES', // any EU-zone country code
  pro: plans.pro.EUR,
  premium: plans.premium.EUR,
  group: {
    pro: plans.pro.EUR_GROUP,
    premium: plans.premium.EUR_GROUP
  }
}

const brazil = {
  countryCode: 'BR',
  pro: plans.pro.BR,
  premium: plans.premium.BR,
  group: {
    pro: plans.pro.BR_GROUP,
    premium: plans.premium.BR_GROUP
  }
}

const britain = {
  countryCode: 'GB',
  pro: plans.pro.GB,
  premium: plans.premium.GB,
  group: {
    pro: plans.pro.GB_GROUP,
    premium: plans.premium.GB_GROUP
  }
}

const canada = {
  countryCode: 'CA',
  pro: plans.pro.CA,
  premium: plans.premium.CA,
  group: {
    pro: plans.pro.CA_GROUP,
    premium: plans.premium.CA_GROUP
  }
}

const usa = {
  countryCode: 'US',
  pro: plans.pro.US,
  premium: plans.premium.US,
  group: {
    pro: plans.pro.US_GROUP,
    premium: plans.premium.US_GROUP
  }
}

const japan = {
  countryCode: 'JP',
  pro: plans.pro.JP,
  premium: plans.premium.JP,
  group: {
    pro: plans.pro.JP_GROUP,
    premium: plans.premium.JP_GROUP
  }
}

const korea = {
  countryCode: 'KR',
  pro: plans.pro.KR,
  premium: plans.premium.KR,
  group: {
    pro: plans.pro.KR_GROUP,
    premium: plans.premium.KR_GROUP
  }
}

const mexico = {
  countryCode: 'MX',
  pro: plans.pro.MX,
  premium: plans.premium.MX,
  group: {
    pro: plans.pro.MX_GROUP,
    premium: plans.premium.MX_GROUP
  }
}

const highGlobal = (countryCode?: string | null): any => ({
  countryCode: countryCode || 'NONE',
  pro: plans.pro.HIGH_GLOBAL,
  premium: plans.premium.HIGH_GLOBAL,
  group: {
    pro: plans.pro.HIGH_GLOBAL_GROUP,
    premium: plans.premium.HIGH_GLOBAL_GROUP
  }
})

const global = (countryCode?: string | null): any => ({
  countryCode: countryCode || 'NONE',
  pro: plans.pro.GLOBAL,
  premium: plans.premium.GLOBAL,
  group: {
    pro: plans.pro.GLOBAL_GROUP,
    premium: plans.premium.GLOBAL_GROUP
  }
})

export const usePricing = (): UsePricing => {
  const { countryCode } = useCountry()
  const { user } = useContext(Context)
  const activeStripeCurrency = user?.subscription?.activeStripeCurrency

  if (activeStripeCurrency === 'EUR') return europe
  if (activeStripeCurrency === 'BRL') return brazil
  if (activeStripeCurrency === 'GBP') return britain
  if (activeStripeCurrency === 'CAD') return canada
  if (activeStripeCurrency === 'AUD') return australia
  if (activeStripeCurrency === 'JPY') return japan
  if (activeStripeCurrency === 'KRW') return korea
  if (activeStripeCurrency === 'MXN') return mexico

  if (activeStripeCurrency === 'USD') {
    if (countryCode === 'US') return usa
    if (highZoneCountryCodes.includes(countryCode || '')) {
      return highGlobal(countryCode)
    }

    return global(null)
  }

  if (countryCode === 'US') return usa
  if (countryCode === 'BR') return brazil
  if (countryCode === 'GB') return britain
  if (countryCode === 'CA') return canada
  if (countryCode === 'AU') return australia
  if (countryCode === 'JP') return japan
  if (countryCode === 'KR') return korea
  if (countryCode === 'MX') return mexico

  if (euroZoneCountryCodes.includes(countryCode || '')) {
    return europe
  }

  if (highZoneCountryCodes.includes(countryCode || '')) {
    return highGlobal(countryCode)
  }

  return global(countryCode)
}
