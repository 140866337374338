import React from 'react'
import classnames from 'classnames'
import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { PromoDetails } from '../promo-details'
import { replacePlaceholders } from '../../lib/misc/replace-placeholders'
import styles from './checkout-details.module.scss'
import { SeatsCounter } from '../group/seats-counter'
import { UseControlLicenses } from '../../hooks/checkout-new/group/use-control-licenses'

interface CheckoutDetailsProps {
  className?: string
  plan: 'premium' | 'pro'
  cycle: 'monthly' | 'yearly'
  titlePromo?: string
  discountPercentage?: number
  hasCampaignYearly?: boolean
  hasCampaignMonthly?: boolean
  onChangePlan(e: React.ChangeEvent<HTMLSelectElement>): void
  isGroupPlan?: boolean
  isEnabledGroupPlan?: boolean
  methodsControlLicenses?: UseControlLicenses
}

export const CheckoutDetails: React.FC<CheckoutDetailsProps> = ({
  className,
  plan,
  cycle,
  titlePromo,
  discountPercentage,
  hasCampaignYearly,
  hasCampaignMonthly,
  onChangePlan,
  isGroupPlan,
  isEnabledGroupPlan,
  methodsControlLicenses
}) => {
  const { i18n } = useLingui()

  return (
    <div className={classnames(className, styles.container)}>
      <div>
        <p className={styles.labelBig}>{i18n._(t`checkout.order`)}</p>

        <select
          className={styles.select}
          onChange={onChangePlan}
          defaultValue={`${cycle}-${plan}`}
          id="plan_type_subscription"
        >
          <option
            id="plan_type_monthly_subscription_option"
            value="monthly-premium"
          >
            {replacePlaceholders(t`checkout_type_monthly_plan`, {
              plan: 'Premium'
            })}
          </option>
          <option
            id="plan_type_yearly_subscription_option"
            value="yearly-premium"
          >
            {replacePlaceholders(t`checkout_type_annual_plan`, {
              plan: 'Premium'
            })}
          </option>
          <option
            id="plan_type_monthly_subscription_option"
            value="monthly-pro"
          >
            {replacePlaceholders(t`checkout_type_monthly_plan`, {
              plan: 'Pro'
            })}
          </option>
          <option id="plan_type_yearly_subscription_option" value="yearly-pro">
            {replacePlaceholders(t`checkout_type_annual_plan`, {
              plan: 'Pro'
            })}
          </option>
        </select>

        {(hasCampaignYearly || hasCampaignMonthly) &&
        ((cycle === 'yearly' && hasCampaignYearly) ||
          (cycle === 'monthly' && hasCampaignMonthly)) ? (
          <PromoDetails
            title={titlePromo}
            labelDiscount={`${discountPercentage}% OFF`}
            description={
              hasCampaignYearly
                ? i18n
                    ._(t`beatfriday_checkout`)
                    .replace('50', `${discountPercentage}`)
                : undefined
            }
          />
        ) : null}

        {isGroupPlan && isEnabledGroupPlan && methodsControlLicenses && (
          <div className={styles.seatsCounter}>
            <SeatsCounter {...methodsControlLicenses} />
          </div>
        )}

        <ul className={styles.list}>
          {isGroupPlan && (
            <li className={styles.item}>
              {i18n
                ._(t`checkout_premium_access`)
                .replace(
                  '**Premium**',
                  `${plan === 'premium' ? 'Premium' : 'Pro'}`
                )}
            </li>
          )}

          <li className={styles.item}>
            {cycle === 'yearly'
              ? i18n._(t`billed_every_year`)
              : i18n._(t`checkout.billed.month`)}
          </li>

          <li className={styles.item}>
            {i18n._(t`checkout.description.renew`)}
          </li>

          <li className={styles.item}>
            {i18n._(t`checkout.description.cancel`)}
          </li>
        </ul>
      </div>
    </div>
  )
}
